import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import "../styles/Map.css";

const Map = () => {
  const mapStyles = {
    height: "100%",
    width: "100%",
    border: "2px solid black",
  };

  const defaultCenter = {
    lat: 53.135517,
    lng: 23.194223,
  };
  console.log(process.env.REACT_APP_GMAPS_API_KEY)
  return (
    <div className="map-container" id="section3">
      <div className="map-header">
        <h2>Lokalizacja</h2>
        <p>ul. Towarowa 28 lok. 27, 15-007 Białystok</p>
      </div>
      <div className="map">
        <LoadScript googleMapsApiKey={process.env.REACT_APP_GMAPS_API_KEY}>
          <GoogleMap
            mapContainerStyle={mapStyles}
            zoom={13}
            center={defaultCenter}
          >
            <Marker position={defaultCenter} />
          </GoogleMap>
        </LoadScript>
      </div>
    </div>
  );
};

export default Map;
