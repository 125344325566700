import React from "react";
import "../styles/Footer.css";

const Footer = () => {
  return (
    <div className="footer" id="section4">
      <div className="footer-card">
        <h2>Kontakt</h2>
        <p>tel. +48 662 958 651</p>
        <p>e-mail: marcinchocha@wp.pl</p>
      </div>
      <div className="footer-card">
        <h2>Adres</h2>
        <p>ul. Towarowa 28 lok.27</p>
        <p>15-007 Białystok</p>
      </div>
      <div className="footer-card">
        <h2>Informacje dodatkowe</h2>
        <p>NIP: 966-121-49-49</p>
        <p>REGON: 200104153</p>
      </div>
    </div>
  );
};

export default Footer;
