// App.js
import React from 'react';
import Header from '../src/components/Header';
import Services from '../src/components/Services';
import ContactForm from '../src/components/ContactForm';
import Map from '../src/components/Map';
import Footer from '../src/components/Footer';
import './App.css';


const App = () => {
  return (
    <div className="app">
      <Header />
      <Services />
      <ContactForm />
      <Map />
      <Footer/>
    </div>
  );
};

export default App;
