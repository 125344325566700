import React, { useEffect, useState } from "react";
import "../styles/Header.css";
import image_logo from "../images/file.png"
const Header = () => {
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 200) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <header className={`header ${scrolled ? "scrolled" : ""}`}>
      <div className="header-logo">
        <h1>GEO-CAD</h1>
        <p>USŁUGI GEODEZYJNO-KARTOGRAFICZNE</p>
        <p className="author">Marcin Chocha</p>
      </div>
      <div className="header-image">
        <img className="infinity-image" src={image_logo} alt="infinity"/>
      </div>
      <div className="header-navigation">
        <nav>
          <ul>
            <li onClick={() => scrollToSection("section1")}>Usługi</li>
            <li onClick={() => scrollToSection("section3")}>Lokalizacja</li>
            <li onClick={() => scrollToSection("section4")}>Kontakt</li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
