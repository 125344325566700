// Services.js
import React from "react";
import "../styles/Services.css";

const Services = () => {
  return (
    <section className="services" id="section1">
      <div className="services-container">
        <div className="services-logo">
          <h2>Oferujemy usługi w zakresie</h2>
        </div>

        <div className="provided-services">
          <div className="card special-card2">
            <h3>Mapy</h3>
            <ul>
              <li>Mapy do celów projektowych</li>
              <li>Mapy poinwentaryzacyjne:</li>
              <ul className="service-list-special">
                <li>- Budynków</li>
                <li>- Przyłączy Wodociągowych</li>
                <li>- Przyłączy Kanalizacyjnych</li>
                <li>- Przyłączy Gazowych</li>
                <li>- Przyłączy Energetycznych</li>
                <li>- Przyłączy Światłowodowych</li>
                <li>- Innych obiektów budowlanych</li>
              </ul>
              <li>Mapy do celów projektowych pod inwestycje liniowe</li>
              <li>Mapy do celów prawnych</li>
              <li>Mapy do celów opiniodawczych</li>
            </ul>
          </div>

          <div className="card special-card">
            <h3>Podziały nieruchomości</h3>
            <ul>
              <li>- Podziały nieruchomości</li>
              <li>- Wytyczenia granic działek</li>
              <li>- Rozgraniczenia nieruchomości</li>
              <li>- Wytyczenia granic działek w lasach</li>
              <li>- Scalenie działek</li>
            </ul>
          </div>
          <div className="card">
            <h3>Inwentaryzacje</h3>
            <ul>
              <li>Inwentaryzacja budynków</li>
              <li>Inwentaryzacja sieci i przyłączy:</li>
              <ul className="service-list-special">
                <li>- Przyłączy Kanalizacyjnych</li>
                <li>- Przyłączy Energetycznych</li>
                <li>- Przyłączy Wodociągowych</li>
                <li>- Przyłączy Gazowych</li>
                <li>- Przyłączy Światłowodowych</li>
              </ul>

              <li>Inwentaryzacje konstrukcji stalowych</li>
              <li>Inwentaryzacja suwnic</li>
              <li>Pomiar powierzchni lokali</li>
            </ul>
          </div>

          <div className="card special-card3">
            <h2 className="title">Wytyczenia</h2>
            <div className="content">
              <ol>
                <li>Wytyczenia Wszelkiego Rodzaju Obiektów Budowlanych:</li>
                <li>
                  <ul className="service-list-special">
                    <li>Budynki</li>
                    <li>Infrastruktura Towarzysząca</li>
                    <li>Przyłącza i Sieci Infrastruktury Technicznej</li>
                  </ul>
                </li>
                <li>
                  Geodezyjna Obsługa Inwestycji: Zapewniamy kompleksową obsługę
                  geodezyjną inwestycji, wspierając realizację projektów na
                  każdym etapie.
                </li>
                <li>
                  Wykrywanie Urządzeń Podziemnych: Oferujemy usługi wykrywania
                  urządzeń podziemnych, co jest kluczowe dla bezpiecznego i
                  efektywnego planowania prac budowlanych.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
