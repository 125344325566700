import React, { useState } from "react";
import emailjs from "emailjs-com";
import "../styles/ContactForm.css";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        formData,
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        alert("Wiadomość wysłana pomyślnie!");
      })
      .catch((error) => {
        console.error("FAILED...", error);
        alert("Wystąpił błąd podczas wysyłania wiadomości.");
      });
  };

  return (
    <section className="contact-form" id="section2">
      <div className="contact-card">
        <div className="contact-logo">
          <h2>Chciałbyś się nas o coś zapytać?</h2>
          <p>Postaramy się odpowiedzieć jak najszybciej :)</p>
        </div>
        <form onSubmit={handleSubmit}>
          <label htmlFor="email">
            Adres e-mail
            <input
              type="email"
              name="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </label>
          <label htmlFor="phone">
            Numer telefonu
            <input
              type="tel"
              name="phone"
              id="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </label>
          <label htmlFor="subject">
            Temat
            <input
              type="text"
              name="subject"
              id="subject"
              value={formData.subject}
              onChange={handleChange}
              required
            />
          </label>
          <label htmlFor="message">
            Wiadomość
            <textarea
              name="message"
              id="message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </label>
          <button type="submit">Wyślij wiadomość</button>
        </form>
      </div>
    </section>
  );
};

export default ContactForm;
